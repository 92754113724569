import { ONE_HOUR_IN_SECONDS } from '../data/time/milliseconds'
import { COLOR_QUERY, MATERIAL_QUERY } from '../data/facets'

import type { Environment } from '../types/environment'

// General Website Defaults
export const SCROLL_TIMEOUT = 20
export const DEFAULT_TITLE = 'Happy Socks'
export const OG_DEFAULT_IMAGE
  = 'https://a.storyblok.com/f/54304/1080x1080/6d27c21538/fw23-10xlifestyle-images-clean-1080x1080-_0001_221013_happysocks_day2_08_693.jpg'
export const PAYMENT_PROVIDERS = ['adyen']
export const STORES_WITH_OPTIONAL_POSTCODE: Environment.CountryCode[] = ['IE', 'AE']
export const REGION_REQUIRED_COUNTRIES: Environment.CountryCode[] = ['US', 'FR']
export const SHIPPING_OPTIONS = ['shipping-option-ups', 'shipping-option-ups-express'] as const
export const SHIPPING_METHODS = [
  'deutsche-post-standard',
  'japan-post-standard',
  'postnord-express',
  'brt-express',
  'seur-express',
  'chronopost-express',
  'dpd-express',
  'dhl-express',
] as const
export const HIDDEN_FLAG_COUNTRIES = ['TW']

// FILTERS
export const ITEMS_PER_FILTER_REQUEST = 64

// PLP
export const ITEMS_PER_PAGE = 64
export const ITEMS_PER_OUTLET_PAGE = 152
export const ITEMS_PER_ROW_SMALL_GRID = 8
export const ITEMS_PER_ROW_LARGE_GRID = 4
export const SKELETONS_PER_PAGE_SMALL_GRID = 24
export const SKELETONS_PER_PAGE_LARGE_GRID = 8

// PDP
export const ITEMS_PER_COLOR_SELECTOR = 4

// Checkout
export const CART_SESSION_DAYS = 30
export const ADYEN_SESSION_EXPIRES_AT = ONE_HOUR_IN_SECONDS
export const WAREHOUSE_ERROR = 'warehouse_error'
export const APPLE_PAY_COUNTRY_ERROR = 'apple_pay_country_error'
export const APPLE_PAY_US_BILLING_COUNTRY_ERROR = 'apple_pay_us_billing_country_error'
export const APPLE_PAY_FATAL_ERROR = 'apple_pay_fatal_error'

// Cookies
export const MARKET_COOKIE = 'marketV2'
export const USER_ID_COOKIE = 'uid'

// Query Params or Search Params
export const MARKET_QUERY = 'market'
export const RETAIN_FILTER_QUERY_IN_CANONICAL_URLS: [string, string[]][] = [
  ['/adults/socks', [COLOR_QUERY, MATERIAL_QUERY]],
  ['/kids/socks', [COLOR_QUERY, MATERIAL_QUERY]],
  ['/collection/sneaker-socks', [COLOR_QUERY]],
  ['/collection/low-socks', [COLOR_QUERY]],
  ['/collection/socks-for-men', [COLOR_QUERY, MATERIAL_QUERY]],
  ['/collection/socks-for-women', [COLOR_QUERY, MATERIAL_QUERY]],
]

// Brink API
export const BRINK_API_ORDER_URL = 'orderv1'
export const BRINK_API_STOCK_URL = 'stockv2'

// Adyen API

// localStorage
export const ADYEN_CHECKOUT_SESSION = 'adyen-checkout__session'
export const CART_TOKENS = 'cart-tokens'
export const HAS_CART_SESSION = 'hasCartSession'
export const CHECKOUT_INFO = 'checkoutInfo'
export const COMPLETED_VIEWS = 'completedViews'
export const ORDER_SESSION = 'currentOrderSession'
export const SELECTED_COUNTRY_PER_STOREFRONT = 'selectedCountryPerStorefront'

// Session Storage
export const CHECKOUT_STEPS = 'checkoutSteps'

// Storyblok Constants
export const HIDE_NEWSLETTER_POPUP_FOR_STOREFRONT = 'HIDE_NEWSLETTER_POPUP_FOR_STOREFRONT'
export const COUNTRIES_EXCL_IMPORT = 'COUNTRIES_EXCL_IMPORT'
export const FREE_SHIPPING_UNTIL = 'FREE_SHIPPING_UNTIL'
export const FREE_SHIPPING_UNTIL_COUNTRIES = 'FREE_SHIPPING_UNTIL_COUNTRIES'
export const DISABLE_NEWSLETTER_BLOCK_ON_PAGES = 'DISABLE_NEWSLETTER_BLOCK_ON_PAGES'
export const OG_THUMBNAIL = 'OG_THUMBNAIL'
export const WIDGET_DEFAULT_8_8_8_STORY_REF_PROD = 'c1e2870a-2422-43a4-b56d-f8d1235b8bc1'
